import {
  PROCESS_PAYMENT_REQUEST,
  PROCESS_PAYMENT_REQUEST_SUCCESS,
  PROCESS_PAYMENT_REQUEST_FAILURE,
  PROCESS_PAYMENT_CLEAN_REQUEST,
  PAYMENT_SETTINGS_FETCH_REQUEST,
  PAYMENT_SETTINGS_FETCH_REQUEST_SUCCESS,
  PAYMENT_SETTINGS_FETCH_REQUEST_FAILURE,
  PAYMENT_SETTINGS_CLEAN_REQUEST,
} from './actionTypes';

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const reducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case PROCESS_PAYMENT_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case PROCESS_PAYMENT_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        errors: {},
      });

    case PROCESS_PAYMENT_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case PROCESS_PAYMENT_CLEAN_REQUEST:
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        errors: {},
      });

    default:
      return state;
  }
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export const paymentSettingsReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case PAYMENT_SETTINGS_FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case PAYMENT_SETTINGS_FETCH_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        errors: {},
      });

    case PAYMENT_SETTINGS_FETCH_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case PAYMENT_SETTINGS_CLEAN_REQUEST:
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        errors: {},
      });

    default:
      return state;
  }
};

export default reducer;
