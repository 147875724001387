import {
  FAMILY_INFO_ADD_REQUEST,
  FAMILY_INFO_ADD_REQUEST_SUCCESS,
  FAMILY_INFO_ADD_REQUEST_FAILURE,
  FAMILY_INFO_FETCH_REQUEST,
  FAMILY_INFO_FETCH_REQUEST_SUCCESS,
  FAMILY_INFO_FETCH_REQUEST_FAILURE,
  FAMILY_INFO_UPDATE_REQUEST,
  FAMILY_INFO_UPDATE_REQUEST_SUCCESS,
  FAMILY_INFO_UPDATE_REQUEST_FAILURE,
  FAMILY_INFO_CLEAN_REQUEST,
  NOMINEE_INFO_ADD_REQUEST,
  NOMINEE_INFO_ADD_REQUEST_SUCCESS,
  NOMINEE_INFO_ADD_REQUEST_FAILURE,
  NOMINEE_INFO_FETCH_REQUEST,
  NOMINEE_INFO_FETCH_REQUEST_SUCCESS,
  NOMINEE_INFO_FETCH_REQUEST_FAILURE,
  NOMINEE_INFO_UPDATE_REQUEST,
  NOMINEE_INFO_UPDATE_REQUEST_SUCCESS,
  NOMINEE_INFO_UPDATE_REQUEST_FAILURE,
  NOMINEE_INFO_CLEAN_REQUEST,
  GUARDIAN_INFO_ADD_REQUEST,
  GUARDIAN_INFO_ADD_REQUEST_SUCCESS,
  GUARDIAN_INFO_ADD_REQUEST_FAILURE,
  GUARDIAN_INFO_FETCH_REQUEST,
  GUARDIAN_INFO_FETCH_REQUEST_SUCCESS,
  GUARDIAN_INFO_FETCH_REQUEST_FAILURE,
  GUARDIAN_INFO_UPDATE_REQUEST,
  GUARDIAN_INFO_UPDATE_REQUEST_SUCCESS,
  GUARDIAN_INFO_UPDATE_REQUEST_FAILURE,
  GUARDIAN_INFO_CLEAN_REQUEST,
} from './actionTypes';

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
export const familyReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case FAMILY_INFO_ADD_REQUEST:
    case FAMILY_INFO_UPDATE_REQUEST:
    case FAMILY_INFO_FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case FAMILY_INFO_ADD_REQUEST_SUCCESS:
    case FAMILY_INFO_UPDATE_REQUEST_SUCCESS:
    case FAMILY_INFO_FETCH_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        errors: {},
      });

    case FAMILY_INFO_ADD_REQUEST_FAILURE:
    case FAMILY_INFO_UPDATE_REQUEST_FAILURE:
    case FAMILY_INFO_FETCH_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case FAMILY_INFO_CLEAN_REQUEST:
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        errors: {},
      });

    default:
      return state;
  }
};

// export default familyReducer;
//nominee
export const nomineeReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case NOMINEE_INFO_ADD_REQUEST:
    case NOMINEE_INFO_UPDATE_REQUEST:
    case NOMINEE_INFO_FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case NOMINEE_INFO_ADD_REQUEST_SUCCESS:
    case NOMINEE_INFO_UPDATE_REQUEST_SUCCESS:
    case NOMINEE_INFO_FETCH_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        errors: {},
      });

    case NOMINEE_INFO_ADD_REQUEST_FAILURE:
    case NOMINEE_INFO_UPDATE_REQUEST_FAILURE:
    case NOMINEE_INFO_FETCH_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case NOMINEE_INFO_CLEAN_REQUEST:
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        errors: {},
      });

    default:
      return state;
  }
};

//guardian
export const guardianReducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case GUARDIAN_INFO_ADD_REQUEST:
    case GUARDIAN_INFO_UPDATE_REQUEST:
    case GUARDIAN_INFO_FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case GUARDIAN_INFO_ADD_REQUEST_SUCCESS:
    case GUARDIAN_INFO_UPDATE_REQUEST_SUCCESS:
    case GUARDIAN_INFO_FETCH_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: action.data,
        errors: {},
      });

    case GUARDIAN_INFO_ADD_REQUEST_FAILURE:
    case GUARDIAN_INFO_UPDATE_REQUEST_FAILURE:
    case GUARDIAN_INFO_FETCH_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case GUARDIAN_INFO_CLEAN_REQUEST:
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        errors: {},
      });

    default:
      return state;
  }
};
