export const ACCOUNT_INFO_FETCH_REQUEST = 'ACCOUNT_INFO_FETCH_REQUEST';
export const ACCOUNT_INFO_FETCH_REQUEST_SUCCESS =
  'ACCOUNT_INFO_FETCH_REQUEST_SUCCESS';
export const ACCOUNT_INFO_FETCH_REQUEST_FAILURE =
  'ACCOUNT_INFO_FETCH_REQUEST_FAILURE';

export const ACCOUNT_INFO_ADD_REQUEST = 'ACCOUNT_INFO_ADD_REQUEST';
export const ACCOUNT_INFO_ADD_REQUEST_SUCCESS =
  'ACCOUNT_INFO_ADD_REQUEST_SUCCESS';
export const ACCOUNT_INFO_ADD_REQUEST_FAILURE =
  'ACCOUNT_INFO_ADD_REQUEST_FAILURE';

export const ACCOUNT_INFO_UPDATE_REQUEST = 'ACCOUNT_INFO_UPDATE_REQUEST';
export const ACCOUNT_INFO_UPDATE_REQUEST_SUCCESS =
  'ACCOUNT_INFO_UPDATE_REQUEST_SUCCESS';
export const ACCOUNT_INFO_UPDATE_REQUEST_FAILURE =
  'ACCOUNT_INFO_UPDATE_REQUEST_FAILURE';

export const ACCOUNT_INFO_CLEAN_REQUEST = 'ACCOUNT_INFO_CLEAN_REQUEST';
