import React, { useEffect } from 'react';

import Header from './Header';
import Footer from './Footer';
import { setLocalStorage } from '../utils/storageUtil';

function Main(props) {
  useEffect(() => {
    return () => {
      setLocalStorage('request_rejected', '');
    };
  }, []);

  return (
    <>
      <div className="page-container">
        <div className="content-wrap">
          <Header />
          <main style={{ marginTop: '4.5em' }} role="main">
            {props.children}
          </main>
        </div>
        {/*<Footer  {...props}/>*/}
      </div>
    </>
  );
}

export default Main;
