import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/css/theme/_all.scss';
import 'react-image-crop/dist/ReactCrop.css';

import App from './routes';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store/configureStore';

import { AuthProvider } from './context/auth';

const store = configureStore();

ReactDOM.render(
  <StrictMode>
    <Suspense
      fallback={
        <div className="loading-container">Error! Please refresh the page</div>
      }
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Router basename={process.env.PUBLIC_URL} history={history}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </Router>
        </ConnectedRouter>
      </Provider>
    </Suspense>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
