export const DOCUMENT_FETCH_REQUEST = 'DOCUMENT_FETCH_REQUEST';
export const DOCUMENT_FETCH_REQUEST_SUCCESS = 'DOCUMENT_FETCH_REQUEST_SUCCESS';
export const DOCUMENT_FETCH_REQUEST_FAILURE = 'DOCUMENT_FETCH_REQUEST_FAILURE';

export const DOCUMENT_ADD_REQUEST = 'DOCUMENT_ADD_REQUEST';
export const DOCUMENT_ADD_REQUEST_SUCCESS = 'DOCUMENT_ADD_REQUEST_SUCCESS';
export const DOCUMENT_ADD_REQUEST_FAILURE = 'DOCUMENT_ADD_REQUEST_FAILURE';

export const DOCUMENT_UPDATE_REQUEST = 'DOCUMENT_UPDATE_REQUEST';
export const DOCUMENT_UPDATE_REQUEST_SUCCESS =
  'DOCUMENT_UPDATE_REQUEST_SUCCESS';
export const DOCUMENT_UPDATE_REQUEST_FAILURE =
  'DOCUMENT_UPDATE_REQUEST_FAILURE';

export const DOCUMENT_CLEAN_REQUEST = 'DOCUMENT_CLEAN_REQUEST';
