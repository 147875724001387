import { applyMiddleware, compose, createStore } from 'redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import createRootReducer from '../reducers';
import history from '../utils/history';

export { history };

const configureStore = (initialState = {}) => {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    initialState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        reduxImmutableStateInvariant()
      )
    )
  );

  return store;
};

export default configureStore;
