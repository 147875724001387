export const PROCESS_PAYMENT_REQUEST = 'PROCESS_PAYMENT_REQUEST';
export const PROCESS_PAYMENT_REQUEST_SUCCESS =
  'PROCESS_PAYMENT_REQUEST_SUCCESS';
export const PROCESS_PAYMENT_REQUEST_FAILURE =
  'PROCESS_PAYMENT_REQUEST_FAILURE';

export const PROCESS_PAYMENT_CLEAN_REQUEST = 'PROCESS_PAYMENT_CLEAN_REQUEST';

export const PAYMENT_SETTINGS_FETCH_REQUEST = 'PAYMENT_SETTINGS_FETCH_REQUEST';
export const PAYMENT_SETTINGS_FETCH_REQUEST_SUCCESS =
  'PAYMENT_SETTINGS_FETCH_REQUEST_SUCCESS';
export const PAYMENT_SETTINGS_FETCH_REQUEST_FAILURE =
  'PAYMENT_SETTINGS_FETCH_REQUEST_FAILURE';

export const PAYMENT_SETTINGS_CLEAN_REQUEST = 'PAYMENT_SETTINGS_CLEAN_REQUEST';
