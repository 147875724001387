import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import personalReducer from '../pages/kyc/personal/reducer';
import accountReducer from '../pages/kyc/account/reducer';
import {
  familyReducer,
  nomineeReducer,
  guardianReducer,
} from '../pages/kyc/family/reducer';
import occupationReducer from '../pages/kyc/occupation/reducer';
import documentReducer from '../pages/kyc/document/reducer';
import addressReducer from '../pages/kyc/address/reducer';
import optionsReducer from '../components/shared/Options/reducer';
import profileReducer from '../pages/profile/reducer';
import applicationStatusReducer from '../pages/dashboard/reducer';
import paymentReducer, {
  paymentSettingsReducer,
} from '../pages/payment/reducer';
import customFieldsReducer from '../components/shared/CustomField/reducer';
import paymentHistoryReducer from '../pages/payment/history/reducer';
import dematAccountRenewReducer, {
  dematAccountRenewPaymentSettingsReducer,
} from '../pages/dematAccountRenew/reducer';

import {
  allLinkReducer,
  availableInstituteReducer,
  linkInstituteReducer,
} from '../pages/institute/reducer.js';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    personals: personalReducer,
    accounts: accountReducer,
    families: familyReducer,
    nominee: nomineeReducer,
    guardian: guardianReducer,
    occupations: occupationReducer,
    documents: documentReducer,
    addresses: addressReducer,
    options: optionsReducer,
    profile: profileReducer,
    fields: customFieldsReducer,
    allLinkInstitutes: allLinkReducer,
    availableLinkInstitutes: availableInstituteReducer,
    linkInstitutes: linkInstituteReducer,
    payments: paymentReducer,
    paymentHistories: paymentHistoryReducer,
    applicationStatus: applicationStatusReducer,
    paymentSetting: paymentSettingsReducer,
    dematAccountRenew: dematAccountRenewReducer,
    dematAccountRenewPaymentSetting: dematAccountRenewPaymentSettingsReducer,
  });
export default createRootReducer;
