export const FAMILY_INFO_FETCH_REQUEST = 'FAMILY_INFO_FETCH_REQUEST';
export const FAMILY_INFO_FETCH_REQUEST_SUCCESS =
  'FAMILY_INFO_FETCH_REQUEST_SUCCESS';
export const FAMILY_INFO_FETCH_REQUEST_FAILURE =
  'FAMILY_INFO_FETCH_REQUEST_FAILURE';

export const FAMILY_INFO_ADD_REQUEST = 'FAMILY_INFO_ADD_REQUEST';
export const FAMILY_INFO_ADD_REQUEST_SUCCESS =
  'FAMILY_INFO_ADD_REQUEST_SUCCESS';
export const FAMILY_INFO_ADD_REQUEST_FAILURE =
  'FAMILY_INFO_ADD_REQUEST_FAILURE';

export const FAMILY_INFO_UPDATE_REQUEST = 'FAMILY_INFO_UPDATE_REQUEST';
export const FAMILY_INFO_UPDATE_REQUEST_SUCCESS =
  'FAMILY_INFO_UPDATE_REQUEST_SUCCESS';
export const FAMILY_INFO_UPDATE_REQUEST_FAILURE =
  'FAMILY_INFO_UPDATE_REQUEST_FAILURE';

export const FAMILY_INFO_CLEAN_REQUEST = 'FAMILY_INFO_CLEAN_REQUEST';

//nomineeAction

export const NOMINEE_INFO_FETCH_REQUEST = 'NOMINEE_INFO_FETCH_REQUEST';
export const NOMINEE_INFO_FETCH_REQUEST_SUCCESS =
  'NOMINEE_INFO_FETCH_REQUEST_SUCCESS';
export const NOMINEE_INFO_FETCH_REQUEST_FAILURE =
  'NOMINEE_INFO_FETCH_REQUEST_FAILURE';

export const NOMINEE_INFO_ADD_REQUEST = 'NOMINEE_INFO_ADD_REQUEST';
export const NOMINEE_INFO_ADD_REQUEST_SUCCESS =
  'NOMINEE_INFO_ADD_REQUEST_SUCCESS';
export const NOMINEE_INFO_ADD_REQUEST_FAILURE =
  'NOMINEE_INFO_ADD_REQUEST_FAILURE';

export const NOMINEE_INFO_UPDATE_REQUEST = 'NOMINEE_INFO_UPDATE_REQUEST';
export const NOMINEE_INFO_UPDATE_REQUEST_SUCCESS =
  'NOMINEE_INFO_UPDATE_REQUEST_SUCCESS';
export const NOMINEE_INFO_UPDATE_REQUEST_FAILURE =
  'NOMINEE_INFO_UPDATE_REQUEST_FAILURE';

export const NOMINEE_INFO_CLEAN_REQUEST = 'NOMINEE_INFO_CLEAN_REQUEST';

//guardianAction

export const GUARDIAN_INFO_FETCH_REQUEST = 'GUARDIAN_INFO_FETCH_REQUEST';
export const GUARDIAN_INFO_FETCH_REQUEST_SUCCESS =
  'GUARDIAN_INFO_FETCH_REQUEST_SUCCESS';
export const GUARDIAN_INFO_FETCH_REQUEST_FAILURE =
  'GUARDIAN_INFO_FETCH_REQUEST_FAILURE';

export const GUARDIAN_INFO_ADD_REQUEST = 'GUARDIAN_INFO_ADD_REQUEST';
export const GUARDIAN_INFO_ADD_REQUEST_SUCCESS =
  'GUARDIAN_INFO_ADD_REQUEST_SUCCESS';
export const GUARDIAN_INFO_ADD_REQUEST_FAILURE =
  'GUARDIAN_INFO_ADD_REQUEST_FAILURE';

export const GUARDIAN_INFO_UPDATE_REQUEST = 'GUARDIAN_INFO_UPDATE_REQUEST';
export const GUARDIAN_INFO_UPDATE_REQUEST_SUCCESS =
  'GUARDIAN_INFO_UPDATE_REQUEST_SUCCESS';
export const GUARDIAN_INFO_UPDATE_REQUEST_FAILURE =
  'GUARDIAN_INFO_UPDATE_REQUEST_FAILURE';

export const GUARDIAN_INFO_CLEAN_REQUEST = 'GUARDIAN_INFO_CLEAN_REQUEST';
