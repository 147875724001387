export const DEMAT_ACCOUNT_RENEW_FETCH_REQUEST =
  'DEMAT_ACCOUNT_RENEW_FETCH_REQUEST';
export const DEMAT_ACCOUNT_RENEW_FETCH_REQUEST_SUCCESS =
  'DEMAT_ACCOUNT_RENEW_FETCH_REQUEST_SUCCESS';
export const DEMAT_ACCOUNT_RENEW_FETCH_REQUEST_FAILURE =
  'DEMAT_ACCOUNT_RENEW_FETCH_REQUEST_FAILURE';

export const DEMAT_ACCOUNT_RENEW_ADD_REQUEST =
  'DEMAT_ACCOUNT_RENEW_ADD_REQUEST';
export const DEMAT_ACCOUNT_RENEW_ADD_REQUEST_SUCCESS =
  'DEMAT_ACCOUNT_RENEW_ADD_REQUEST_SUCCESS';
export const DEMAT_ACCOUNT_RENEW_ADD_REQUEST_FAILURE =
  'DEMAT_ACCOUNT_RENEW_ADD_REQUEST_FAILURE';

export const DEMAT_ACCOUNT_RENEW_UPDATE_REQUEST =
  'DEMAT_ACCOUNT_RENEW_UPDATE_REQUEST';
export const DEMAT_ACCOUNT_RENEW_UPDATE_REQUEST_SUCCESS =
  'DEMAT_ACCOUNT_RENEW_UPDATE_REQUEST_SUCCESS';
export const DEMAT_ACCOUNT_RENEW_UPDATE_REQUEST_FAILURE =
  'DEMAT_ACCOUNT_RENEW_UPDATE_REQUEST_FAILURE';

export const DEMAT_ACCOUNT_RENEW_CLEAN_REQUEST =
  'DEMAT_ACCOUNT_RENEW_CLEAN_REQUEST';

export const DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_FETCH_REQUEST =
  'DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_FETCH_REQUEST';
export const DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_FETCH_REQUEST_SUCCESS =
  'DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_FETCH_REQUEST_SUCCESS';
export const DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_FETCH_REQUEST_FAILURE =
  'DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_FETCH_REQUEST_FAILURE';

export const DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_CLEAN_REQUEST =
  'DEMAT_ACCOUNT_RENEW_PAYMENT_SETTINGS_CLEAN_REQUEST';
