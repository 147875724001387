import React, { useContext } from 'react';

import FullLogo from '../assets/img/nic.png';

import { Link } from 'react-router-dom';
import { isAuthenticated } from '../utils/jwtUtil';

import { AuthContext } from '../context/auth';

const Header = () => {
  const { logout } = useContext(AuthContext);

  return (
    <>
      <section className="topheader">
        <div className="container">
          <div className="tollfree"></div>
        </div>
      </section>
      <nav className="navbar navbar-inverse navbar-fixed-top">
        <div className="container">
          <div className="navbar-header col-xs-12 col-sm-2 col-md-2 ">
            <Link to="/" className="navbar-brand">
              <img src={FullLogo} alt="logo" />
            </Link>{' '}
          </div>
          <div className="col-xs-12 col-sm-10 col-md-12 application">
            {isAuthenticated() ? (
              <>
                <Link to="/" className="applynowbtn" onClick={() => logout()}>
                  Log Out
                </Link>
                <Link to="/profile" className="applynowbtn">
                  Profile
                </Link>
                <Link to="/dashboard" className="applynowbtn">
                  Dashboard
                </Link>
              </>
            ) : (
              <>
                <Link to="/demat-account-renew" className="applynowbtn">
                  Payment
                </Link>
                <Link to="/login" className="applynowbtn">
                  Log In
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
