import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm col-md-2">
              <div className="card card-primary">
                <div className="card-header">Current accounts</div>
              </div>
            </div>

            <div className="col-sm col-md-2">
              <div className="card card-primary">
                <div className="card-header">Savings accounts</div>
              </div>
            </div>

            <div className="col-sm col-md-2">
              <div className="card card-primary">
                <div className="card-header" style={{ paddingRight: '17px' }}>
                  Fixed term deposits
                </div>
              </div>
            </div>

            <div className="col-sm col-md-2">
              <div className="card card-primary">
                <div className="card-header">Investments</div>
              </div>
            </div>

            <div className="col-sm col-md-2">
              <div className="card card-primary">
                <div className="card-header">Loans</div>
              </div>
            </div>

            <div className="col-sm col-md-2">
              <div className="card card-primary">
                <div className="card-header">Credit cards</div>
              </div>
            </div>
            {/*{column1}*/}
          </div>
          {/*{row}*/}
          <hr />
          {/*<div className="row">*/}
          {/*    <p className="col-sm" style={{textAlign: "center"}}>*/}
          {/*        <a href="/" className="navbar-brand">*/}
          {/*            <img src={Logo} alt="Logo" width="40"/>*/}
          {/*        </a>*/}
          {/*        Citytech | All Rights Reserved | Terms of Service*/}
          {/*    </p>*/}
          {/*</div>*/}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
