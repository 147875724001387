export const INSTITUTE_CLEAN_REQUEST = 'INSTITUTE_CLEAN_REQUEST';

export const INSTITUTE_FETCH_REQUEST = 'INSTITUTE_FETCH_REQUEST';
export const INSTITUTE_FETCH_REQUEST_SUCCESS =
  'INSTITUTE_FETCH_REQUEST_SUCCESS';
export const INSTITUTE_FETCH_REQUEST_FAILURE =
  'INSTITUTE_FETCH_REQUEST_FAILURE';

export const CLEAN_ALL_LINKED_INSTITUTE_FETCH_REQUEST =
  'CLEAN_ALL_LINKED_INSTITUTE_FETCH_REQUEST';

export const ALL_LINKED_INSTITUTE_FETCH_REQUEST =
  'ALL_LINKED_INSTITUTE_FETCH_REQUEST';
export const ALL_LINKED_INSTITUTE_FETCH_REQUEST_SUCCESS =
  'ALL_LINKED_INSTITUTE_FETCH_REQUEST_SUCCESS';
export const ALL_LINKED_INSTITUTE_FETCH_REQUEST_FAILURE =
  'ALL_LINKED_INSTITUTE_FETCH_REQUEST_FAILURE';

export const LINK_INSTITUTE_FETCH_REQUEST = 'LINK_INSTITUTE_FETCH_REQUEST';
export const LINK_INSTITUTE_FETCH_REQUEST_SUCCESS =
  'LINK_INSTITUTE_FETCH_REQUEST_SUCCESS';
export const LINK_INSTITUTE_FETCH_REQUEST_FAILURE =
  'LINK_INSTITUTE_FETCH_REQUEST_FAILURE';
export const LINK_INSTITUTE_CLEAN_REQUEST = 'LINK_INSTITUTE_CLEAN_REQUEST';
