import React, { createContext, useState } from 'react';
import axios from 'axios';

import {
  API_URL,
  JWT_TOKEN,
  USER_FULL_NAME,
  USER_ID,
  ENFORCE_STATUS,
  USER_PHOTO,
} from '../../constants/config';
import { clearLocalStorage, setLocalStorage } from '../../utils/storageUtil';
import { isAuthenticated } from '../../utils/jwtUtil';
import history from '../../utils/history';

const AuthContext = createContext({
  fullName: undefined,
  clientId: undefined,
  photo: undefined,
  authenticated: false,
  enforceMobileVerification: false,
  enforceEmailVerification: false,
});

const AuthProvider = (props) => {
  const [kycTab, setKycTab] = useState(undefined);

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [authenticated, setAuthenticated] = useState(
    isAuthenticated() || false
  );

  const state = {
    loading,
    setLoading,
    authenticated,
    message,
    kycTab,
    setKycTab,
  };

  const login = (formData) => {
    setLoading(true);
    return axios
      .post(API_URL + '/client/v1/auths', formData, {
        headers: {
          'X-XSRF-TOKEN': '1002',
          'X-MULTITENANCY-TOKEN': '1002',
        },
      })
      .then((response) => {
        setLocalStorage(JWT_TOKEN, response.data.data.token);
        setLocalStorage(USER_FULL_NAME, response.data.data.fullName);
        setLocalStorage(USER_ID, response.data.data.clientId);
        setLocalStorage(USER_PHOTO, response.data.data.photo);
        setLocalStorage(ENFORCE_STATUS, {
          enforceEmailVerification: response.data.data.enforceEmailVerification,
          enforceMobileVerification:
            response.data.data.enforceMobileVerification,
        });
        setAuthenticated(true);
        history.push('/dashboard');
        return response;
      })
      .catch((error) => {
        setLoading(false);
        setMessage(error.response.data.message);
      });
  };

  const logout = () => {
    setLoading(false);
    setAuthenticated(false);
    setMessage(undefined);
    clearLocalStorage(JWT_TOKEN);
    clearLocalStorage(USER_FULL_NAME);
    history.push('/');
  };

  return (
    <AuthContext.Provider
      {...props}
      value={{
        ...state,
        message: message,
        setMessage: setMessage,
        loading: loading,
        setLoading: setLoading,
        login: login,
        logout: logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
