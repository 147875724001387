import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

import Main from '../layout/Main';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import RestrictRoute from './RestrictRoute';

export const AsyncHome = loadable(() => import('../pages/home/Home'));
export const AsyncRegister = loadable(() => import('../pages/auth/signup'));

export const AsyncAuth = loadable(() => import('../pages/auth/'));
export const AsyncForgot = loadable(() =>
  import('../pages/auth/ForgetPassword')
);
export const AsyncReset = loadable(() => import('../pages/auth/ResetPassword'));

export const AsyncPaymentSuccess = loadable(() =>
  import('../pages/payment/esewa')
);
export const AsyncPaymentFailure = loadable(() =>
  import('../pages/payment/esewa/PaymentFailure')
);
export const AsyncPaymentHistory = loadable(() =>
  import('../pages/payment/history')
);

export const AsyncKhaltiPayment = loadable(() =>
  import('../pages/payment/khalti')
);

export const AsyncDashboard = loadable(() => import('../pages/dashboard'));
export const AsyncProfile = loadable(() => import('../pages/profile'));

export const AsyncKyc = loadable(() => import('../pages/kyc/main'));

export const AsyncDematAccountRenew = loadable(() =>
  import('../pages/dematAccountRenew')
);
export const AsyncDematAccountRenewPaymentSuccess = loadable(() =>
  import('../pages/dematAccountRenew/esewa/')
);
export const AsyncDematAccountRenewPaymentSuccessKhalti = loadable(() =>
  import('../pages/dematAccountRenew/khalti/')
);
export const AsyncDematAccountRenewPaymentFailure = loadable(() =>
  import('../pages/dematAccountRenew/esewa/DematAccountRenewPaymentFailure')
);
export const AsyncFreePaymentSuccess = loadable(() =>
  import('../pages/payment/PaymentSuccess')
);

export const AsyncTermAndCondition = loadable(() =>
  import('../pages/TermsCondition')
);
export const AsyncError = loadable(() => import('../pages/Error'));
export const AsyncNotFound = loadable(() => import('../pages/NotFound'));

const App = () => (
  <>
    <Switch>
      <PublicRoute exact path="/" layout={Main} component={AsyncHome} />
      <RestrictRoute path="/login" layout={Main} component={AsyncAuth} />
      <PublicRoute path="/500" layout={Main} component={AsyncError} />
      <PublicRoute
        path="/terms-and-condition"
        layout={Main}
        component={AsyncTermAndCondition}
      />

      <RestrictRoute path="/register" layout={Main} component={AsyncRegister} />
      <RestrictRoute path="/forgot" layout={Main} component={AsyncForgot} />
      <RestrictRoute path="/reset" layout={Main} component={AsyncReset} />
      <PrivateRoute
        path="/payment-success"
        layout={Main}
        component={AsyncPaymentSuccess}
      />
      <PrivateRoute
        path="/payment-failure"
        layout={Main}
        component={AsyncPaymentFailure}
      />
      <PrivateRoute
        path="/payment-history"
        layout={Main}
        component={AsyncPaymentHistory}
      />
      <PrivateRoute
        path="/payment-message-khalti"
        layout={Main}
        component={AsyncKhaltiPayment}
      />
      <PrivateRoute
        path="/dashboard"
        layout={Main}
        component={AsyncDashboard}
      />
      <PrivateRoute path="/kyc" layout={Main} component={AsyncKyc} />
      <PrivateRoute path="/profile" layout={Main} component={AsyncProfile} />

      <PublicRoute
        path="/demat-account-renew"
        layout={Main}
        component={AsyncDematAccountRenew}
      />
      <PublicRoute
        path="/demat-account-renew-payment-success"
        layout={Main}
        component={AsyncDematAccountRenewPaymentSuccess}
      />
      <PublicRoute
        path="/demat-account-renew-payment-failure"
        layout={Main}
        component={AsyncDematAccountRenewPaymentFailure}
      />
      <PublicRoute
        path="/demat-account-renew-payment-message-khalti"
        layout={Main}
        component={AsyncDematAccountRenewPaymentSuccessKhalti}
      />

      <PublicRoute
        path="/demat-account-renew-payment-successful"
        layout={Main}
        component={AsyncFreePaymentSuccess}
      />

      <PublicRoute layout={Main} component={AsyncNotFound} />
    </Switch>
  </>
);

export default withRouter(App);
