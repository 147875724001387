import {
  OPTIONS_FETCH_REQUEST,
  OPTIONS_FETCH_REQUEST_SUCCESS,
  OPTIONS_FETCH_REQUEST_FAILURE,
  OPTIONS_CLEAN_REQUEST,
} from './actionTypes';

const INITIAL_STATE = {
  payload: [],
  loading: false,
  errors: {},
};

/**
 * A reducer takes two arguments, the current state and an action.
 */
const reducer = (state, action) => {
  state = state || INITIAL_STATE;

  switch (action.type) {
    case OPTIONS_FETCH_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case OPTIONS_FETCH_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        payload: [
          ...state.payload,
          {
            data: action.data,
            item: action.optionName,
          },
        ],
        errors: {},
      });

    case OPTIONS_FETCH_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        errors: action.error,
      });

    case OPTIONS_CLEAN_REQUEST:
      return Object.assign({}, state, {
        loading: false,
        payload: [],
        errors: {},
      });

    default:
      return state;
  }
};

export default reducer;
